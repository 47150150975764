<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">系统日志</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item>系统设置</el-breadcrumb-item>
        <el-breadcrumb-item>系统日志</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="card-box">
      <div class="screen-box">
        <div class="input-box">
          <div class="title-box">客户端IP:</div>
          <el-input v-model="IP" placeholder="请输入客户端IP"></el-input>
        </div>
        <div class="input-box">
          <div class="title-box">模块ID:</div>
          <el-input v-model="moduleID" placeholder="请输入模块ID"></el-input>
        </div>
        <div class="input-box">
          <div class="title-box">日志类别:</div>
          <el-select
            v-model="logType"
            class="card-select"
            placeholder="操作类别"
            filterable
            clearable
          >
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="input-box">
          <div class="title-box">操作类别:</div>
          <el-select
            v-model="actionID"
            class="card-select"
            placeholder="日志类别"
            filterable
            clearable
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">日志期间:</div>
          <el-date-picker
            v-model="duration"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <button class="btn-pre" @click="getLogs(1)">
          <i class="fa fa-search"></i>查询
        </button>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          height="500"
          border
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
          :empty-text="loading ? '加载中...' : '暂无数据'"
        >
          <el-table-column label="#" width="55">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="ID" label="ID" width="60"> </el-table-column>
          <el-table-column prop="ActionID" label="操作类别" width="60">
            <template slot-scope="scope">
              {{ scope.row.ActionID | formAction(options2) }}
            </template>
          </el-table-column>

          <el-table-column prop="Description" label="消息内容" width="150">
            <template slot-scope="scope">
              <div class="content-title">{{ scope.row.Description }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="IP" label="客户端IP" width="80">
          </el-table-column>
          <el-table-column prop="UserAgent" label="浏览器类型" width="150">
            <template slot-scope="scope">
              <div class="content-title">{{ scope.row.UserAgent }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="DateLine" label="操作日期" width="120">
          </el-table-column>
          <el-table-column prop="LogType" label="日志类别" width="60">
          </el-table-column>
          <el-table-column prop="Url" label="Url" width="100">
          </el-table-column>
          <el-table-column label="操作" width="50">
            <template slot-scope="scope">
              <i class="fa fa-search" @click="getLogsId(scope.row.ID)"></i>
              <!-- <i class="fa fa-trash" v-if="powers.delete"></i> -->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="getLogs(1)"
          @current-change="getLogs"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 公告详情 -->

    <el-dialog
      title="日志详情"
      :visible.sync="noticeDrawer"
      width="800px"
      :before-close="handleNoticeClose"
      top="13vh"
    >
      <div class="dialog-main">
        <div class="add-common">
          <div class="content">
            <div class="time-box">
              <span>发布于{{ dateLine }}</span>
            </div>
            <div class="html" v-html="html"></div>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleNoticeClose">取消</el-button
        ><el-button class="pop-save" @click="handleNoticeClose">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getLogs, getLogsId, deleteLogsId } from "@/api/user";
export default {
  filters: {
    formAction(id, data) {
      let name = "";
      data.forEach((item) => {
        if (item.value === id) {
          name = item.label;
        }
      });
      return name;
    },
  },
  data() {
    return {
      active: 1,
      value1: null,
      size: 10,
      total: 2,
      page: 1,
      tableData: [],
      options1: [
        {
          value: 1,
          label: "Info",
        },
        {
          value: 3,
          label: "Error",
        },
        {
          value: 99,
          label: "Login",
        },
      ],
      options2: [
        {
          value: 1,
          label: "Login",
        },
        {
          value: 2,
          label: "Registry",
        },
        {
          value: 3,
          label: "Add",
        },
        {
          value: 4,
          label: "Delete",
        },
        {
          value: 5,
          label: "Update",
        },
        {
          value: 6,
          label: "Search",
        },
        {
          value: 7,
          label: "Export",
        },
        {
          value: 8,
          label: "Import",
        },
        {
          value: 9,
          label: "Audit",
        },
        {
          value: 10,
          label: "Print",
        },
      ],
      messageList: [
        {
          name: "您有一个新公告《XXX》,请及时查看",
          title: "公告提醒",
          time: "18小时前",
        },
        {
          name: "您有一个新公告《XXX》,请及时查看",
          title: "公告提醒",
          time: "18小时前",
        },
      ],
      direction: "rtl",
      noticeDrawer: false,
      html: "我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容 我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容 我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容",
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      actionID: null,
      IP: null,
      logType: null,
      moduleID: null,
      duration: null,
      dateLine: null,
      loading: true,
    };
  },
  methods: {
    deleteItem(index) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.messageList.splice(index, 1);
        this.$message.success("删除成功");
      });
    },
    getWorkflows() {},
    handleNoticeClose() {
      this.noticeDrawer = false;
    },
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
    getLogs(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      let startDate = null;
      let endDate = null;
      if (this.duration) {
        startDate = this.duration[0];
        endDate = this.duration[1];
      }
      const data = {
        page: this.page,
        size: this.size,
        actionID: this.actionID,
        IP: this.IP,
        startDate: startDate,
        endDate: endDate,
        logType: this.logType,
        moduleID: this.moduleID,
      };
      getLogs(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
        }
        this.loading = false;
      });
    },
    getLogsId(id) {
      getLogsId({ id }).then((res) => {
        if (res.status === 200) {
          this.noticeDrawer = true;
          this.dateLine = res.response.DateLine;
          this.html = res.response.Description;
        }
      });
    },
  },
  created() {
    this.getButtonPower();
    this.getLogs();
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 10px;
      .input-box {
        display: flex;
        align-items: center;
        height: 34px;
        .title-box {
          padding: 0 12px;
          border: 1px solid #d2d6de;
          line-height: 32px;
          border-right: none;
          color: #555;
          font-size: 14px;
        }
      }
      .screen-input-box {
        margin-top: 24px;
      }
      .btn-pre {
        margin-top: 24px;
      }
      .btn-add {
        margin-top: 24px;
      }
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .el-date-editor {
        margin-right: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 100px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
      .fa {
        margin-right: 10px;
        color: #3c8dbc;
        cursor: pointer;
      }
      .fa:hover {
        color: #72afd2;
      }
    }
  }
  .dialog-main {
    border-bottom: 2px solid #e6e6e6;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
  }
  .dialog-footer {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .add-common {
    width: 100%;
    height: 100%;
    padding: 0 20px 10px 20px;
    box-sizing: border-box;
    .content {
      .title {
        font-size: 14px;
        color: #333333;
        margin-top: 0px;
      }
      .time-box {
        font-size: 12px;
        color: #999999;
        margin-top: 18px;
        margin-bottom: 18px;
        span {
          margin-right: 30px;
        }
      }
      .html {
        color: #333333;
        font-size: 14px;
        word-wrap: break-word; /* 旧版浏览器支持 */
        overflow-wrap: break-word; /* 标准属性 */
      }
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    font-weight: normal;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 2px solid #e6e6e6;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.content-title {
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
</style>